import React from 'react';
import ReactDOM from 'react-dom';
import NewsItems from './NewsItems.js';
import './OnCoins.css';
 
const defaultTab = 'sol';
const tabs = ['news', 'nfts', 'eth', 'stable', 'defi', 'sol', 'dao'];

function cleanState(activeTab) {
    var startState = {};
    tabs.forEach((tab) => {
      startState[tab + 'Active'] = false;
    });
    startState[activeTab + 'Active'] = true;
    return startState;
}

let handleButtonClick = (evt) => {
    let elements = document.querySelectorAll('button');
    for (let i = 0; i < elements.length; i++) {
        ReactDOM.findDOMNode(elements[i]).style.color = 'white';
    }
    ReactDOM.findDOMNode(evt.target).style.color = 'rgb(205,126,105)';
}
//                    let btn_class = this.state.black ? "blackButton" : "whiteButton";

class OnCoins extends React.Component {
  constructor(props) {
    super(props);
    this.state = cleanState(defaultTab);
  }
  
  render() {
    return (
      <div className="main">
        <div className="wrap-top-bg">
          <h1>Blockchain Tech News <span id="byline">by William Mougayar</span></h1>
          <h2 id="sub_title">500+ sources &nbsp;&nbsp;&nbsp;<span className="newsmap"><a href="//map.oncoins.org/">Newsmap</a></span></h2>

          <h3>
            <button 
              type="button"
              className={"link-button " + (defaultTab === 'news' ? 'orange' : '')}
              onClick={(evt) => {
                handleButtonClick(evt);
                this.setState(cleanState('news'));
               }}>
              All
            </button>
            <button 
              type="button"
              className={"link-button " + (defaultTab === 'nfts' ? 'orange' : '')}
              onClick={(evt) => {
                handleButtonClick(evt);
                this.setState(cleanState('nfts'));
               }}>
              NFT
            </button>
            <button 
              type="button"
              className={"link-button " + (defaultTab === 'eth' ? 'orange' : '')}
              onClick={(evt) => {
                handleButtonClick(evt);
                this.setState(cleanState('eth'));
               }}>
              Ethereum
            </button>
            <button 
              type="button"
              className={"link-button " + (defaultTab === 'stable' ? 'orange' : '')}
              onClick={(evt) => {
                handleButtonClick(evt);
                this.setState(cleanState('stable'));
               }}>
              Stablecoins
            </button>
            <button 
              type="button"
              className={"link-button " + (defaultTab === 'defi' ? 'orange' : '')}
              onClick={(evt) => {
                handleButtonClick(evt);
                this.setState(cleanState('defi'));
               }}>
              DeFi
            </button>
            <button 
              type="button"
              className={"link-button " + (defaultTab === 'sol' ? 'orange' : '')}
              onClick={(evt) => {
                handleButtonClick(evt);
                this.setState(cleanState('sol'));
               }}>
              Solana
            </button>
            <button 
              type="button"
              className={"link-button " + (defaultTab === 'dao' ? 'orange' : '')}
              onClick={(evt) => {
                handleButtonClick(evt);
                this.setState(cleanState('dao'));
               }}>
              DAO
            </button>
          </h3>
        </div>

        <div className={`wrap-mid-cont-main ${this.state.newsActive ? "active" : "hidden"}`}>
          <NewsItems url='main' />
        </div>
        <div className={`wrap-mid-cont-main ${this.state.nftsActive ? "active" : "hidden"}`}>
          <NewsItems url='nfts' />
        </div>
        <div className={`wrap-mid-cont-main ${this.state.ethActive ? "active" : "hidden"}`}>
          <NewsItems url='eth' />
        </div>
        <div className={`wrap-mid-cont-main ${this.state.stableActive ? "active" : "hidden"}`}>
          <NewsItems url='stable' />
        </div>
        <div className={`wrap-mid-cont-main ${this.state.defiActive ? "active" : "hidden"}`}>
          <NewsItems url='defi' />
        </div>
        <div className={`wrap-mid-cont-main ${this.state.solActive ? "active" : "hidden"}`}>
          <NewsItems url='sol' />
        </div>
        <div className={`wrap-mid-cont-main ${this.state.daoActive ? "active" : "hidden"}`}>
          <NewsItems url='dao' />
        </div>
      </div>
    );
  }
}

export default OnCoins;