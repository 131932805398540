import React from 'react';
//import AutoUpdate from './AutoUpdate.js';
import './NewsItems.css';

const updateInterval = 300000;

function timeDifference(current, previous) {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
        
    var elapsed = current - previous;

    if (elapsed < 2 * msPerMinute) {
         return Math.round(elapsed/1000) + ' seconds ago';   
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' minutes ago';   
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' hours ago';   
    }

    else if (elapsed < msPerMonth) {
        return 'approximately ' + Math.round(elapsed/msPerDay) + ' days ago';   
    }

    else if (elapsed < msPerYear) {
        return 'approximately ' + Math.round(elapsed/msPerMonth) + ' months ago';   
    }

    else {
        return 'approximately ' + Math.round(elapsed/msPerYear ) + ' years ago';   
    }
}

class NewsItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start: 0,
      news_items: []
    };
    this.updateMe = this.updateMe.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  render() {
    //console.log('updated ' + this.props.url);
    var currentDate = new Date();
    const newsItems = this.state.news_items.map((props, index) =>
      <div className="news_item" key={index} onClick={this.handleChange}>
        <h4 className="news_headline"><a rel="noreferrer" target="_blank" href={props[1]}>{props[0]}</a></h4>
        <div className="news_meta"><span className="source">{props[3]}</span>&nbsp;&nbsp;<span className="time">{timeDifference(currentDate, props[2])}</span></div>
      </div>
    );
    return (
      <div className="wrap-mid-cont">
        {newsItems}
      </div>
    )
  }
  updateMe(data) {
    const newState = {news_items: data};
    this.setState(newState);
  }
  fetchData() {
    var self = this;
    var topic = this.props.url;
    fetch('https://oncoins-app.s3.amazonaws.com/topics.gz')
      .then(function(response) {
        return response.json()
      }).then(function(json) {
        console.log(topic);
        console.log(json[topic]);
        self.updateMe(json[topic]);
      }).catch(function(ex) {
        console.log('parsing failed', ex)
    })
  }
  componentDidMount() {
    this.fetchData();
    setInterval(this.fetchData, parseInt(updateInterval));
    //setInterval(this.fetchData, parseInt(this.props.interval));
  }

};

export default NewsItems;